// // 存放路由
// const routes_main = [
//   // 路由重定向，默认打开Home页
//
//   {
//     path: '/home',
//     // redirect: '/home',
//     component: () => import('@/layout'),
//     children: [
//       // 系统首页路由
//       { path: '/home', name: 'Home', meta: { icon: 'el-icon-s-home', title: '首页' }, component: () => import('@/views/Home') },
//       //碳小课堂模块
//       {
//         path: '/class',
//         name: 'Class',
//         redirect: '/knowledge',
//         meta: { icon: 'el-icon-tickets', title: '碳中和小课堂' },
//         component: () => import('@/views/Class'),
//         children: [
//           // 知识子路由
//           {
//             path: '/knowledge',
//             name: 'Knowledge',
//             meta: { icon: 'el-icon-video-camera', title: '碳中和知识' },
//             component: () => import('@/views/Class/Knowledge'),
//           },
//           // 视频模块子路由
//           {
//             path: '/video',
//             name: 'Video',
//             meta: { icon: 'el-icon-video-camera', title: '碳中和视频' },
//             component: () => import('@/views/Class/Videos'),
//           },
//
//
//       //一点通
//       // {
//       //   path: '/information',
//       //   name: 'Information',
//       //   redirect: '/news',
//       //   meta: { icon: 'el-icon-s-flag', title: '碳中和一点通' },
//       //   component: () => import('@/views/Information'),
//       //   children: [
//       //     // 新闻资讯子路由
//       //     {
//       //       path: '/news',
//       //       name: 'News',
//       //       meta: { icon: 'el-icon-s-flag', title: '资讯' },
//       //       component: () => import('@/views/Information/News'),
//       //     },
//           // // 活动模块子路由
//           // {
//           //   path: '/activity',
//           //   name: 'Activity',
//           //   meta: { icon: 'el-icon-s-flag', title: '活动' },
//           //   component: () => import('@/views/Information/Activity'),
//           // },
//   //       ],
//   //     },
//   //     // 办公室
//   //     {
//   //       path: '/office',
//   //       name: 'Office',
//   //       redirect: '/library',
//   //       meta: { icon: 'el-icon-s-management', title: '碳中和办公室' },
//   //       component: () => import('@/views/Office'),
//   //       children: [
//   //         { path: '/library', name: 'library', meta: { title: '碳中和文库' }, component: () => import('@/views/Office/Library') },
//   //         // { path: '/organization', name: 'organization', meta: { title: 'QU碳组织' }, component: () => import('@/views/Office/Organization') },
//   //         { path: '/feedback', name: 'feedback', meta: { title: '联系我们' }, component: () => import('@/views/Office/FeedBack') },
//   //       ],
//   //     },
//   //     // 在行动
//   //     {
//   //       path: '/action',
//   //       name: 'Action',
//   //       redirect: '/blog',
//   //       meta: { icon: 'el-icon-user-solid', title: '低碳在行动' },
//   //       component: () => import('@/views/Action'),
//   //       children: [
//   //         {
//   //           path: '/blog',
//   //           name: 'Blog',
//   //           meta: { icon: 'el-icon-s-flag', title: '碳中和动态' },
//   //           component: () => import('@/views/Action/Blog'),
//   //         },
//   //         {
//   //           path: '/actions',
//   //           name: 'Actions',
//   //           meta: { icon: 'el-icon-s-flag', title: '碳中和行动' },
//   //           component: () => import('@/views/Action/Actions'),
//   //         },
//   //       ],
//   //     },
//   //     // 打卡页面
//   //     {
//   //       path: '/post_blog',
//   //      component: () => import('@/views/Action/BlogPost')
//       }
//     ],
//   },
// ];
//
//
//
// export default routes_main

const routes_main = [
  {
    path: '/home',
    component: () => import('@/layout'),
    children: [
      { path: '/home', name: 'Home', meta: { icon: 'el-icon-s-home', title: '首页' }, component: () => import('@/views/Home') },
      {
        path: '/class',
        name: 'Class',
        redirect: '/knowledge',
        meta: { icon: 'el-icon-tickets', title: '碳中和小课堂' },
        component: () => import('@/views/Class'),
        children: [
          { path: '/knowledge', name: 'Knowledge', meta: { icon: 'el-icon-video-camera', title: '碳中和知识' }, component: () => import('@/views/Class/Knowledge') },
          { path: '/video', name: 'Video', meta: { icon: 'el-icon-video-camera', title: '碳中和视频' }, component: () => import('@/views/Class/Videos') },
        ],
      },
    ],
  },
];

export default routes_main;
